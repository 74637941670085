import './App.css';
import {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PaymentFormPage from "./components/PaymentFormPage/PaymentFormPage";
import ConfirmPage from "./components/ConfirmPage/ConfirmPage";
import {useShowPopup} from "@vkruglikov/react-telegram-web-app"

function App() {
	const showPopup = useShowPopup();
  const [isInvalidVersion, setIsInvalidVersion] = useState(false);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      if (!window.Telegram.WebApp.isVersionAtLeast("6.9")) {
        if (window.Telegram.WebApp.isVersionAtLeast("6.2")) {
					showPopup({message: "Пожалуйста обновите Telegram до последней версии для того чтобы использовать это приложение."})
        } else {
          console.log("the version is not supported")
          setIsInvalidVersion(true)
        }
      }
      window.Telegram.WebApp.expand()
    }
  }, [showPopup])

	return (
		<>
		 {isInvalidVersion &&
				(<div>
					<div>
						<h1>
							Извините, но ваша версия Telegram устарела!
						</h1>
						<h1>
							Для использования этого приложения вам необходимо обновить Telegram.
						</h1>
					</div>
				</div>)}
      {!isInvalidVersion && (
        <BrowserRouter>
          <Routes>
						<Route path={'payment_form_page'} element={<PaymentFormPage />} />
						<Route path={'confirm_page'} element={<ConfirmPage />} />
					</Routes>
        </BrowserRouter>)}
		</>
	);
}

export default App;
