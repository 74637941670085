import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import s from "./PaymentFormPage.module.css";
import { useShowPopup, useInitData } from "@vkruglikov/react-telegram-web-app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../Loader";

const PaymentFormPage = () => {
  const showPopup = useShowPopup();
  const [offerId, setOfferId] = useState("");
  const [productName, setProductName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Банк РФ");
  const [email, setEmail] = useState("");
  const [price, setPrice] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [InitDataUnsafe, InitData] = useInitData();
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      const query = new URLSearchParams(location.search);
      let paramId = query.get("id");
      let paramName = query.get("name");
      let paramPrice = query.get("price");
      setOfferId(paramId);
      setProductName(paramName);
      setPrice(paramPrice);
    }
    if (location.state) {
      const { offerId, paymentMethod, email, productName, price } =
        location.state;
      setOfferId(offerId);
      setPaymentMethod(paymentMethod);
      setEmail(email);
      setProductName(productName);
      setPrice(price);
    }
  }, [location]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleContinueClick = async (event) => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      showPopup({
        message: "Пожалуйста, введите ваш E-mail.",
      });
      return;
    }

    setIsLoading(true); // Начало загрузки

    const createContractUrl =
      "https://grasplab.ru/fastapi_app/lava_top/create_contract";

    try {
      const response = await fetch(createContractUrl, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          offerId: offerId,
          currency: paymentMethod === "Банк РФ" ? "RUB" : "USD",
          userId: InitDataUnsafe.user.id,
          userInitData: InitData,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        navigate("/confirm_page", {
          state: {
            offerId: offerId,
            productName: productName,
            paymentMethod: paymentMethod,
            email: email,
            price: price,
            paymentUrl: data.paymentUrl, // Передаем ссылку на оплату
          },
        });
      } else {
        showPopup({
          message: "Что-то пошло не так, обратитесь в поддержку.",
        });
      }
    } catch (error) {
      showPopup({
        message: "Что-то пошло не так, обратитесь в поддержку.",
      });
    } finally {
      setIsLoading(false); // Конец загрузки
    }
  };

  const selectPaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="wrapper">
        <div className="container">
          <div className="content">
            <div className="progress">
              <div className="circle completed">
                <FontAwesomeIcon icon={faCheck} style={{ color: "#2c2c2e" }} />
                <span>Выбор</span>
              </div>
              <div className="line"></div>
              <div className="circle">
                <span>Данные</span>
              </div>
              <div className="line"></div>
              <div className="circle">
                <span>Оплата</span>
              </div>
            </div>
            <div className={s.label}>Наименование товара</div>
            <div className={s.productName}>{productName}</div>
            <div className={s.label}>Метод оплаты</div>
            <div className={s.paymentMethods}>
              <input
                type="button"
                value="Банк РФ"
                onClick={selectPaymentMethod}
                className={`${s.paymentMethodButton} ${
                  paymentMethod === "Банк РФ" ? s.selected : ""
                }`}
              />
              <input
                type="button"
                value="Иностранный банк"
                onClick={selectPaymentMethod}
                className={`${s.paymentMethodButton} ${
                  paymentMethod === "Иностранный банк" ? s.selected : ""
                }`}
              />
            </div>
            <div className={s.label}>Ваш E-mail</div>
            <div className={s.section}>
              <input
                type="email"
                value={email}
                placeholder="example@gmail.com"
                onChange={handleEmailChange}
              />
            </div>
            <div className={s.label}>Стоимость</div>
            <div className={s.price}>{price} RUB</div>
          </div>
          <div className="empty_space"></div>
          <div className="buttons">
            <button onClick={handleContinueClick} className={s.button}>
              Далее
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFormPage;
